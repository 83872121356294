<template>
    <div class="d-flex flex-nowrap justify-end">
        <v-icon
            v-if="canEdit(claimName) && !isEditDisabled"
            class="mr-2"
            @click="$emit('editItem',item)">
            mdi-pencil
        </v-icon>
        <slot name="cellButtons" :item="item" />
        <v-icon v-if="hasEditPage" @click="$emit('goToEditPage',item)">
            mdi-arrow-right-thick
        </v-icon>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: null,
        },
        claimName: {
            type: String,
            default: null,
        },
        isEditDisabled: {
            type: Boolean,
            default: false,
        },
        hasEditPage: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
